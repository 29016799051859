
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'
import { ProductTypeDetail } from '@/types/product'
import { FileInfo } from '@/types/common'
@Component({
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgList = list.map(item => {
        return item.filePrefix + item.fileUrl
      })
      return imgList || []
    }
  }
})
@KeepAlive
export default class ProductTypeList extends Vue {
  private loading = false
  private info: Array<ProductTypeDetail> = []
  private total = 0
  private page = 1
  private size = 10

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.product.selectProductTypeByPage, {
      page: this.page,
      size: this.size
    }).then((res) => {
      this.info = res.list || []
      this.total = res.total || 0
    }).finally(() => {
      this.loading = false
    })
  }

  // 新增
  onAdd () {
    this.$router.push({
      name: 'productTypeAdd'
    })
  }

  // 详情
  onDetail (id: string) {
    this.$router.push({
      name: 'productTypeDetail',
      params: { id }
    })
  }

  // 编辑
  onEdit (id: string) {
    this.$router.push({
      name: 'productTypeEdit',
      params: { id }
    })
  }

  // 删除
  onDelete (id: string) {
    this.$confirm('确认删除该产品类型吗？', '提示').then(() => {
      this.$axios.post(this.$apis.product.deleteProductType, {
        productTypeId: id
      }).then(() => {
        this.$message.success('操作成功')
        this.getData()
      })
    })
  }
}
